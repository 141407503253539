import { render, staticRenderFns } from "./storeFilter.vue?vue&type=template&id=23b8c37b&scoped=true&"
import script from "./storeFilter.vue?vue&type=script&lang=js&"
export * from "./storeFilter.vue?vue&type=script&lang=js&"
import style0 from "./storeFilter.vue?vue&type=style&index=0&id=23b8c37b&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b8c37b",
  null
  
)

export default component.exports